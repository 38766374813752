import * as React from 'react';
import { cn } from '#/app/utils/misc.tsx';

type ContainerProps<T extends React.ElementType> = {
	as?: T;
	className?: string;
	children: React.ReactNode;
};

export function Container<T extends React.ElementType = 'div'>({
	as,
	className,
	children,
}: Omit<React.ComponentPropsWithoutRef<T>, keyof ContainerProps<T>> &
	ContainerProps<T>) {
	let Component = as ?? 'div';

	return (
		<Component className={cn('mx-auto max-w-7xl px-6 lg:px-8', className)}>
			<div className="mx-auto max-w-2xl lg:max-w-none">{children}</div>
		</Component>
	);
}
